import React from 'react';
import { Footer, Header } from 'components';

const Contact = () => {
  return (
    <div className="common_page">
      <Header subTitle="Contact Us" />

      <div className="common_page-wrapper">
        <div className="common_page-text">The fastest way to reaching our team is by contacting us through email.</div>
        <div className="common_page-text">Email Us: <a href="mailto: support@merchx.com" className="common_page-link">support@merchx.com</a></div>
        <div className="common_page-text">
          Please allow up to 24 hours for a response. We are working hard to make sure all
          customers are satisfied as fast as possible!
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Contact;