import React from 'react';
import { Link } from 'react-router-dom';
import logoDark from 'assets/images/logo_dark.png';
import './logo_dark.scss';

const LogoDark = () => {
  return (
    <Link to="/">
      <img className="logo_dark" src={logoDark} alt="" />
    </Link>
  );
};

export default LogoDark;