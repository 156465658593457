import axios from 'axios';
import * as Config from '../config';

const instance = axios.create({
  baseURL: Config.sendgridApiUrl,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const sendgridSend = async ({ to = Config.adminEmail, subject = '', body = '', isHtml = false }) => {
  try {
    await instance({
      method: 'post',
      url: '/send_email',
      data: {
        to: to,
        from: Config.adminEmail,
        subject,
        body
      }
    });
  } catch (err) {
    throw new Error(err);
  }
};
