import React from 'react';
import uuid from 'uuid';


export default [
  {
    id: uuid(),
    title: '1. Account Terms',
    description: (
      <div>
        <ul className="accordion__decimal-list">
          <li>
            <p>1.</p>
            <p>
              To access and use the Services, you must register for a MerchX account (“Account”) by providing your
              full legal name, current address, phone number, a valid email address, and any other information
              indicated as required. MerchX may reject your application for an Account, or cancel an existing Account,
              for any reason, in our sole discretion.
            </p>
          </li>
          <li>
            <p>2.</p>
            <p>
              You must be the older of: (i) 18 years, or (ii) at least the age of majority in the jurisdiction where
              you reside and from which you use the Services to open an Account.
            </p>
          </li>
          <li>
            <p>3.</p>
            <p>
              You confirm that you are receiving any Services provided by MerchX for the purposes of carrying on a
              business activity and not for any personal, household or family purpose.
            </p>
          </li>
          <li>
            <p>4.</p>
            <p>
              You acknowledge that MerchX will use the email address you provide on opening an Account or as
              updated by you from time to time as the primary method for communication with you.
            </p>
          </li>
          <li>
            <p>5.</p>
            <p>
              You are responsible for keeping your password secure. MerchX cannot and will not be liable for any
              loss or damage from your failure to maintain the security of your Account and password.
            </p>
          </li>
          <li>
            <p>6.</p>
            <p>
              You are responsible for all activity and content such as photos, images, videos, graphics, written
              content, audio files, code, information, or data uploaded, collected, generated, stored, displayed,
              distributed, transmitted or exhibited on or in connection with your Account (“Materials”).
            </p>
          </li>
          <li>
            <p>7.</p>
            <p>
              A breach or violation of any term in the Terms of Service, including the AUP, as determined in the
              sole discretion of MerchX may result in an immediate termination of your Services.
            </p>
          </li>
        </ul>
      </div>
    )
  },
  {
    id: uuid(),
    title: '2. Account Activation',
    description: (
      <div>
        <ul className="accordion__decimal-list">
          <b>2.1 Brand Owner</b>
          <li>
            <p>1.</p>
            <p>
              Subject to section 2.1(2), the person signing up for the Service by opening an Account will be
              the contracting party (“Brand Owner”) for the purposes of our Terms of Service and will be the
              person who is authorized to use any corresponding Account we may provide to the Brand Owner in
              connection with the Service.
            </p>
          </li>
          <li>
            <p>2.</p>
            <p>
              If you are signing up for the Services on behalf of your employer, your employer shall be the Brand
              Owner. If you are signing up for the Services on behalf of your employer, then you must use your
              employer-issued email address and you represent and warrant that you have the authority to bind your
              employer to our Terms of Service.
            </p>
          </li>
          <li>
            <p>3.</p>
            <p>
              Your MerchX Store can only be associated with one Brand Owner. A Brand Owner may have multiple
              MerchX Brands. “Brand” means the online store or physical retail location(s) associated with the Account.
            </p>
          </li>
        </ul>
        <ul className="accordion__decimal-list">
          <b>2.2 User Accounts</b>
          <li>
            <p>1.</p>
            <p>
              Based on your MerchX pricing plan, you can create one or more user accounts (“User Accounts”)
              allowing other people to access the Account. With User Accounts, the Brand Owner can set permissions
              and let other people work in their Account while determining the level of access by User Accounts to
              specific business information (for example, you can limit User Account access to sales information
              on the Reports page or prevent User Accounts from changing general store settings).
            </p>
          </li>
          <li>
            <p>2.</p>
            <p>
              The Brand Owner is responsible and liable for the acts, omissions and defaults arising from use of
              User Accounts in the performance of obligations under these Terms of Service as if they were the
              Brand Owner’s own acts, omissions or defaults.
            </p>
          </li>
          <li>
            <p>3.</p>
            <p>
              The Brand Owner and the users under User Accounts are each referred to as a “MerchX User”.
            </p>
          </li>
        </ul>
        <ul className="accordion__decimal-list">
          <b>2.3 MerchX Payments Accounts</b>
          <li>
            <p>1.</p>
            <p>
              Upon completion of sign up for the Service, MerchX will create a MerchX Payment account
              on your behalf, using your email address.
            </p>
          </li>
          <li>
            <p>2.</p>
            <p>
              You acknowledge that MerchX Payments will be your default payment(s) gateway(s) and that it
              is your sole responsibility as the Brand Owner to activate and maintain these accounts. If
              you do not wish to keep the payment accounts active, it is your responsibility to deactivate them.
            </p>
          </li>
        </ul>
        <ul className="accordion__decimal-list">
          <b>2.4 Domain Names</b>
          <li>
            <p>1.</p>
            <p>
              Upon purchasing a domain name through MerchX, domain registration will be preset to automatically
              renew each year so long as your MerchX Account remains active. You acknowledge that it is your sole
              responsibility to deactivate the auto-renewal function should you choose to do so.
            </p>
          </li>
        </ul>
      </div>
    )
  },
  {
    id: uuid(),
    title: '3. General Conditions',
    description: (
      <div>
        <p>
          You must read, agree with and accept all of the terms and conditions contained in these Terms of Service,
          including the AUP and the Privacy Policy before you may become a MerchX User.
        </p><br/>

        <ul className="accordion__decimal-list">
          <li>
            <p>1.</p>
            <p>
              Technical support in respect of the Services is only provided to MerchX Users.
            </p>
          </li>
          <li>
            <p>2.</p>
            <p>
              The Terms of Service shall be governed by and interpreted in accordance with the laws of the
              Province of North Carolina and the laws of The United States applicable therein, without regard to
              principles of conflicts of laws. The United Nations Convention on Contracts for the International
              Sale of Goods will not apply to these Terms of Service and is hereby expressly excluded.
            </p>
          </li>
          <li>
            <p>3.</p>
            <p>
              You acknowledge and agree that MerchX may amend these Terms of Service at any time by posting the
              relevant amended and restated Terms of Service on MerchX’s website, and such amendments to the Terms
              of Service are effective as of the date of posting. Your continued use of the Services after the
              amended Terms of Service are posted to MerchX’s website constitutes your agreement to, and
              acceptance of, the amended Terms of Service. If you do not agree to any changes to the Terms
              of Service, do not continue to use the Service.
            </p>
          </li>
          <li>
            <p>4.</p>
            <p>
              You may not use the MerchX Services for any illegal or unauthorized purpose nor may you, in
              the use of the Service, violate any laws in your jurisdiction (including but not limited to
              copyright laws), the laws applicable to you in your customer’s jurisdiction, or the laws of
              The United States and the Province of North Carolina. You will comply with all applicable laws,
              rules and regulations in your use of the Service and your performance of obligations under the
              Terms of Service.
            </p>
          </li>
          <li>
            <p>5.</p>
            <p>
              You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service,
              use of the Services, or access to the Services without the express written permission by MerchX.
            </p>
          </li>
          <li>
            <p>6.</p>
            <p>
              You shall not purchase search engine or other pay per click keywords (such as Google AdWords),
              or domain names that use MerchX or MerchX trademarks and/or variations and misspellings thereof.
            </p>
          </li>
          <li>
            <p>7.</p>
            <p>
              Questions about the Terms of Service should be sent to <a href="mailto: support@merchx.com">support@merchx.com</a>.
            </p>
          </li>
          <li>
            <p>8.</p>
            <p>
              You understand that your Materials (not including credit card information), may be transferred
              unencrypted and involve (a) transmissions over various networks; and (b) changes to conform
              and adapt to technical requirements of connecting networks or devices. Credit card information
              is always encrypted during transfer over networks.
            </p>
          </li>
          <li>
            <p>9.</p>
            <p>
              You acknowledge and agree that your use of the Services, including information transmitted to
              or stored by MerchX, is governed by its privacy policy.
            </p>
          </li>
          <li>
            <p>10.</p>
            <p>
              The Terms of Service may be available in languages other than English. To the extent of any
              inconsistencies or conflicts between these English Terms of Service and MerchX’s Terms of
              Service available in another language, the most current English version of the Terms of
              Service will prevail.
            </p>
          </li>
          <li>
            <p>11.</p>
            <p>
              All the terms and provisions of the Terms of Service shall be binding upon and inure to the
              benefit of the parties to the Terms of Service and to their respective heirs, successors, permitted
              assigns and legal representatives. MerchX shall be permitted to assign these Terms of Service
              without notice to you or consent from you. You shall have no right to assign or otherwise transfer
              the Terms of Service, or any of your rights or obligations hereunder, to any third party without
              MerchX’s prior written consent, to be given or withheld in MerchX’s sole discretion.
            </p>
          </li>
          <li>
            <p>12.</p>
            <p>
              If any provision, or portion of the provision, in these Terms of Service is, for any reason,
              held to be invalid, illegal or unenforceable in any respect, then such invalidity, illegality
              or unenforceability will not affect any other provision (or the unaffected portion of the provision)
              of the Terms of Service, and the Terms of Service will be construed as if such invalid, illegal or
              unenforceable provision, or portion of the provision, had never been contained within the Terms of Service.
            </p>
          </li>
        </ul>
      </div>
    )
  },
  {
    id: uuid(),
    title: '4. MerchX Contracting Party',
    description: (
      <div>
        <ul className="accordion__decimal-list">
          <li>
            <p>1.</p>
            <p>
              If the billing address of your Store is located in the United States or Canada, this Section 4(1) applies to you:
              <br/><span>a. “MerchX Contracting Party” means MerchX LLC, a United States corporation, with offices
              located at Burlington, North Carolina, 27215, United States.</span>
              <br/><span>b. The parties irrevocably and unconditionally submit to the exclusive jurisdiction of
              the courts of the Province of North Carolina with respect to any dispute or claim arising out of or in connection with the Terms of Service. The United Nations Convention on Contracts for the International Sale of Goods will not apply to these Terms of Service and is hereby expressly excluded.</span>
            </p>
          </li>
        </ul>
      </div>
    )
  },
  {
    id: uuid(),
    title: '5. MerchX Rights',
    description: (
      <div>
        <ul className="accordion__decimal-list">
          <li>
            <p>1.</p>
            <p>
              We reserve the right to modify or terminate the Services for any reason, without notice at any
              time. Not all Services and features are available in every jurisdiction and we are under no
              obligation to make any Services or features available in any jurisdiction.
            </p>
          </li>
          <li>
            <p>2.</p>
            <p>
              We reserve the right to refuse service to anyone for any reason at any time.
            </p>
          </li>
          <li>
            <p>3.</p>
            <p>
              We may, but have no obligation to, remove Materials and suspend or terminate Accounts if we
              determine in our sole discretion that the goods or services offered via a Store, or the
              Materials uploaded or posted to a Store, violate our AUP or these Terms of Service.
            </p>
          </li>
          <li>
            <p>4.</p>
            <p>
              Verbal or written abuse of any kind (including threats of abuse or retribution) of any MerchX
              customer, MerchX employee, member, or officer will result in immediate Account termination.
            </p>
          </li>
          <li>
            <p>5.</p>
            <p>
              MerchX does not pre-screen Materials and it is in our sole discretion to refuse or remove
              any Materials from the Service, including your Store.
            </p>
          </li>
          <li>
            <p>6.</p>
            <p>
              We reserve the right to provide our services to your competitors and make no promise of
              exclusivity in any particular market segment. You further acknowledge and agree that MerchX
              employees and contractors may also be MerchX customers/merchants and that they may compete with
              you, although they may not use your Confidential Information (as defined in Section 6) in doing so.
            </p>
          </li>
          <li>
            <p>7.</p>
            <p>
              In the event of a dispute regarding Account ownership, we reserve the right to request documentation
              to determine or confirm Account ownership. Documentation may include, but is not limited to, a
              scanned copy of your business license, government issued photo ID, the last four digits of the
              credit card on file, your status as an employee of an entity, etc.
            </p>
          </li>
          <li>
            <p>8.</p>
            <p>
              MerchX retains the right to determine, in our sole judgment, rightful Account ownership and
              transfer an Account to the rightful Brand Owner. If we are unable to reasonably determine the
              rightful Brand Owner, without prejudice to our other rights and remedies, MerchX reserves the
              right to temporarily disable an Account until resolution has been determined between the
              disputing parties.
            </p>
          </li>
        </ul>
      </div>
    )
  },
  {
    id: uuid(),
    title: '6. Confidentiality',
    description: (
      <div>
        <ul className="accordion__decimal-list">
          <li>
            <p>1.</p>
            <p>
              “Confidential Information” shall include, but shall not be limited to, any and all
              information associated with a party’s business and not publicly known, including specific
              business information, technical processes and formulas, software, customer lists,
              prospective customer lists, names, addresses and other information regarding customers
              and prospective customers, product designs, sales, costs (including any relevant processing
              fees), price lists, and other unpublished financial information, business plans and marketing
              data, and any other confidential and proprietary information, whether or not marked as
              confidential or proprietary. MerchX’s Confidential Information includes all information
              that you receive relating to us, or to the Services, that is not known to the general public
              including information related to our security program and practices.
            </p>
          </li>
          <li>
            <p>2.</p>
            <p>
              Each party agrees to use the other party’s Confidential Information solely as necessary
              for performing its obligations under these Terms of Service and in accordance with any other
              obligations in these Terms of Service including this Section 6. Each party agrees that it
              shall take all reasonable steps, at least substantially equivalent to the steps it takes to
              protect its own proprietary information, to prevent the duplication, disclosure or use of any
              such Confidential Information, other than (i) by or to its employees, agents and subcontractors
              who must have access to such Confidential Information to perform such party’s obligations hereunder,
              who each shall treat such Confidential Information as provided herein, and who are each subject to
              obligations of confidentiality to such party that are at least as stringent as those contained
              herein; or (ii) as required by any law, regulation, or order of any court of proper jurisdiction
              over the parties and the subject matter contained in these Terms of Service, provided that, if
              legally permitted, the receiving party shall give the disclosing party prompt written notice and
              use commercially reasonable efforts to ensure that such disclosure is accorded confidential
              treatment. Confidential Information shall not include any information that the receiving party
              can prove: (A) was already in the public domain, or was already known by or in the possession
              of the receiving party, at the time of disclosure of such information; (B) is independently
              developed by the receiving party without use of or reference to the other party’s Confidential
              Information, and without breaching any provisions of these Terms of Service; or (C) is
              thereafter rightly obtained by the receiving party from a source other than the disclosing
              party without breaching any provision of these Terms of Service.
            </p>
          </li>
        </ul>
      </div>
    )
  },
  {
    id: uuid(),
    title: '7. Limitation of Liability',
    description: (
      <div>
        <ul className="accordion__decimal-list">
          <li>
            <p>1.</p>
            <p>
              You expressly understand and agree that, to the extent permitted by applicable laws, MerchX
              shall not be liable for any direct, indirect, incidental, special, consequential or exemplary
              damages, including but not limited to, damages for loss of profits, goodwill, use, data or
              other intangible losses resulting from the use of or inability to use the Service.
            </p>
          </li>
          <li>
            <p>2.</p>
            <p>
              To the extent permitted by applicable laws, in no event shall MerchX or our suppliers be
              liable for lost profits or any special, incidental or consequential damages arising out of
              or in connection with our site, our Services or these Terms of Service (however arising
              including negligence). You agree to indemnify and hold us and (as applicable) our parent,
              subsidiaries, affiliates, MerchX partners, officers, directors, agents, employees, and suppliers
              harmless from any claim or demand, including reasonable attorneys’ fees, made by any third
              party due to or arising out of your breach of these Terms of Service or the documents it
              incorporates by reference (including the AUP), or your violation of any law or the rights of
              a third party.
            </p>
          </li>
          <li>
            <p>3.</p>
            <p>
              Your use of the Services is at your sole risk. The Services are provided on an “as is” and “as
              available” basis without any warranty or condition, express, implied or statutory.
            </p>
          </li>
          <li>
            <p>4.</p>
            <p>
              MerchX does not warrant that the Services will be uninterrupted, timely, secure, or error-free.
            </p>
          </li>
          <li>
            <p>5.</p>
            <p>
              MerchX does not warrant that the results that may be obtained from the use of the
              Services will be accurate or reliable.
            </p>
          </li>
          <li>
            <p>6.</p>
            <p>
              MerchX does not warrant that the quality of any products, services, information, or other
              materials purchased or obtained by you through the Services will meet your expectations,
              or that any errors in the Services will be corrected.
            </p>
          </li>
        </ul>
      </div>
    )
  },
  {
    id: uuid(),
    title: '8. Waiver and Complete Agreement',
    description: (
      <div>
        <p>
          The failure of MerchX to exercise or enforce any right or provision of the Terms of Service shall
          not constitute a waiver of such right or provision. The Terms of Service, including the documents
          it incorporates by reference, constitute the entire agreement between you and MerchX and govern
          your use of the Services and your Account, superseding any prior agreements between you and
          MerchX (including, but not limited to, any prior versions of the Terms of Service).
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: '9. Intellectual Property and Customer Content',
    description: (
      <div>
        <ul className="accordion__decimal-list">
          <li>
            <p>1.</p>
            <p>
              We do not claim any intellectual property rights over the Materials you provide to the
              MerchX Service. All Materials you upload remain yours. You can remove your MerchX Brand
              Store at any time by deleting your Account.
            </p>
          </li>
          <li>
            <p>2.</p>
            <p>
              By uploading Materials, you agree: (a) to allow other internet users to view the Materials
              you post publicly to your Brand Store; (b) to allow MerchX to store, and in the case of
              Materials you post publicly, display and use your Materials; and (c) that MerchX can, at
              any time, review and delete all the Materials submitted to its Service, although MerchX
              is not obligated to do so.
            </p>
          </li>
          <li>
            <p>3.</p>
            <p>
              You retain ownership over all Materials that you upload to the Brand Store; however, by
              making your Brand Store public, you agree to allow others to view Materials that you post
              publicly to your Store. You are responsible for compliance of the Materials with any applicable
              laws or regulations.
            </p>
          </li>
          <li>
            <p>4.</p>
            <p>
              MerchX shall have the non-exclusive right and license to use the names, trademarks, service marks
              and logos associated with your Brand Store to promote the Service.
            </p>
          </li>
        </ul>
      </div>
    )
  },
  {
    id: uuid(),
    title: '10. MerchX Shipping',
    description: (
      <div>
        <p>
          IIf you are located in either Canada or the United States, you can purchase shipping labels from
          within your MerchX administrative console (“MerchX Shipping”) for delivery of goods purchased
          from your MerchX Store. In addition to these Terms of Service, your access to and use of MerchX
          Shipping is subject to the MerchX Shipping Terms of Service.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: '11. Payment of Fees',
    description: (
      <div>
        <ul className="accordion__decimal-list">
          <li>
            <p>1.</p>
            <p>
              You will pay the Fees applicable to your subscription to Online Service (“Subscription Fees”)
              and any other applicable fees, including but not limited to applicable fees relating to the
              value of sales made through your Store when using all payment providers other than MerchX
              Payments (“Transaction Fees”), and any fees relating to your purchase or use of any products
              or services such as MerchX Payments, shipping, apps, domain names, or Third Party Services
              (“Additional Fees”). Together, the Subscription Fees, Transaction Fees and the Additional
              Fees are referred to as the “Fees”.
            </p>
          </li>
          <li>
            <p>2.</p>
            <p>
              You must keep a valid payment method on file with us to pay for all incurred and recurring
              Fees. MerchX will charge applicable Fees to any valid payment method that you authorize
              (“Authorized Payment Method”), and MerchX will continue to charge the Authorized Payment
              Method for applicable Fees until the Services are terminated, and any and all outstanding
              Fees have been paid in full. Unless otherwise indicated, all Fees and other charges are in U.S.
              dollars, and all payments shall be in U.S. currency.
            </p>
          </li>
          <li>
            <p>3.</p>
            <p>
              Subscription Fees are paid in advance and will be billed in 30 day intervals (each such date,
              a “Billing Date”). Transaction Fees and Additional Fees will be charged from time to time at
              MerchX’s discretion. You will be charged on each Billing Date for all outstanding Fees that
              have not previously been charged. Fees will appear on an invoice, which will be sent to the
              Brand Owner via the email provided. As well, an invoice will appear on the Account page of
              your Store’s administration console. Users have approximately two weeks to bring up and
              settle any issues with the billing of Subscription Fees.
            </p>
          </li>
          <li>
            <p>4.</p>
            <p>
              If we are not able to process payment of Fees using an Authorized Payment Method, we will make
              a second attempt to process payment using any Authorized Payment Method 3 days later. If the
              second attempt is not successful, we will make a final attempt 3 days following the second
              attempt. If our final attempt is not successful, we may suspend and revoke access to your
              Account and the Services. Your Account will be reactivated upon your payment of any outstanding
              Fees, plus the Fees applicable to your next billing cycle. You may not be able to access your
              Account or your storefront during any period of suspension. If the outstanding Fees remain unpaid
              for 60 days following the date of suspension, MerchX reserves the right to terminate your Account.
            </p>
          </li>
          <li>
            <p>5.</p>
            <p>
              All Fees are exclusive of applicable federal, provincial, state, local or other governmental
              sales, goods and services (including Goods and Sales Tax under the Goods and Services Tax Act,
              Chapter 117A of Singapore), harmonized or other taxes, fees or charges now in force or enacted
              in the future (“Taxes”).
            </p>
          </li>
          <li>
            <p>6.</p>
            <p>
              You are responsible for all applicable Taxes that arise from or as a result of your subscription
              to or purchase of MerchX’s products and services. To the extent that MerchX charges these Taxes,
              they are calculated using the tax rates that apply based on the billing address you provide to
              us. Such amounts are in addition to the Fees for such products and services and will be billed
              to your Authorized Payment Method. If you are exempt from payment of such Taxes, you must
              provide us with evidence of your exemption, which in some jurisdictions includes an original
              certificate that satisfies applicable legal requirements attesting to tax-exempt status. Tax
              exemption will only apply from and after the date we receive evidence satisfactory to MerchX
              of your exemption. If you are not charged Taxes by MerchX, you are responsible for determining
              if Taxes are payable, and if so, self-remitting Taxes to the appropriate tax authorities in your
              jurisdiction.
            </p>
          </li>
          <li>
            <p>7.</p>
            <p>
              For the avoidance of doubt, all sums payable by you to MerchX under these Terms of Service
              shall be paid free and clear of any deductions or withholdings whatsoever. Other than Taxes
              charged by MerchX to you and remitted to the appropriate tax authorities on your behalf, any
              deductions or withholdings that are required by law shall be borne by you and paid separately
              to the relevant taxation authority. MerchX shall be entitled to charge the full amount of Fees
              stipulated under these Terms of Service to your Authorized Payment Method ignoring any such
              deduction or withholding that may be required.
            </p>
          </li>
          <li>
            <p>8.</p>
            <p>
              You must maintain an accurate location in the administration menu of your MerchX Store. If you
              change jurisdictions you must promptly update your location in the administration menu.
            </p>
          </li>
          <li>
            <p>9.</p>
            <p>
              MerchX does not provide refunds.
            </p>
          </li>
        </ul>
      </div>
    )
  },
  {
    id: uuid(),
    title: '12. Cancellation and Termination',
    description: (
      <div>
        <ul className="accordion__decimal-list">
          <li>
            <p>1.</p>
            <p>
              ou may cancel your Account and terminate the Terms of Service at any time by
              contacting <a href="mailto: support@merchx.com">support@merchx.com</a> and
              then following the specific instructions indicated to you in MerchX’s response.
            </p>
          </li>
          <li>
            <p>2.</p>
            <p>
              Upon termination of the Services by either party for any reason:
              <ul className="accordion__decimal-list">
                <li>
                  <p>1.</p>
                  <p>
                    MerchX will cease providing you with the Services and you will no longer be able to
                    access your Account;.
                  </p>
                </li>
                <li>
                  <p>2.</p>
                  <p>
                    unless otherwise provided in the Terms of Service, you will not be entitled to any
                    refunds of any Fees, pro rata or otherwise;
                  </p>
                </li>
                <li>
                  <p>3.</p>
                  <p>
                    any outstanding balance owed to MerchX for your use of the Services through the
                    effective date of such termination will immediately become due and payable in full; and
                  </p>
                </li>
                <li>
                  <p>4.</p>
                  <p>
                    your Brand Store website will be taken offline
                  </p>
                </li>
              </ul>
            </p>
          </li>
          <li>
            <p>3.</p>
            <p>
              If you purchased a domain name through MerchX, upon cancellation your domain will no longer be
              automatically renewed. Following termination, it will be your sole responsibility to handle all
              matters related to your domain with the domain provider.
            </p>
          </li>
          <li>
            <p>4.</p>
            <p>
              If at the date of termination of the Service, there are any outstanding Fees owing by you, you
              will receive one final invoice via email. Once that invoice has been paid in full, you will not
              be charged again.
            </p>
          </li>
          <li>
            <p>5.</p>
            <p>
              We reserve the right to modify or terminate the MerchX Service, the Terms of Service and/or your
              Account for any reason, without notice at any time. Termination of the Terms of Service shall
              be without prejudice to any rights or obligations which arose prior to the date of termination.
            </p>
          </li>
          <li>
            <p>6.</p>
            <p>
              Fraud: Without limiting any other remedies, MerchX may suspend or terminate your Account if we
              suspect that you (by conviction, settlement, insurance or escrow investigation, or otherwise)
              have engaged in fraudulent activity in connection with the use of the Services.
            </p>
          </li>
        </ul>
      </div>
    )
  },
  {
    id: uuid(),
    title: '13. Modifications to the Service and Prices',
    description: (
      <div>
        <ul className="accordion__decimal-list">
          <li>
            <p>1.</p>
            <p>
              Prices for using the Services are subject to change upon 30 days’ notice from MerchX.
              Such notice may be provided at any time by posting the changes to the MerchX Site
              (merchx.com) or the administration menu of your MerchX Store via an announcement.
            </p>
          </li>
          <li>
            <p>2.</p>
            <p>
              MerchX reserves the right at any time, and from time to time, to modify or discontinue,
              the Services (or any part thereof) with or without notice.
            </p>
          </li>
          <li>
            <p>3.</p>
            <p>
              MerchX shall not be liable to you or to any third party for any modification, price
              change, suspension or discontinuance of the Service.
            </p>
          </li>
        </ul>
      </div>
    )
  },
  {
    id: uuid(),
    title: '14. Third Party Services, Experts, and Experts Marketplace',
    description: (
      <div>
        <ul className="accordion__decimal-list">
          <li>
            <p>1.</p>
            <p>
              MerchX may from time to time recommend, provide you with access to, or enable third party
              software, applications (“Apps”), products, services or website links (collectively,
              “Third Party Services”) for your consideration or use. Such Third Party Services are
              made available only as a convenience, and your purchase, access or use of any such Third
              Party Services is solely between you and the applicable third party services provider
              (“Third Party Provider”). In addition to these Terms of Service, you also agree to be bound
              by the additional service-specific terms applicable to services you purchase from, or that are
              provided by, Third Party Providers.
            </p>
          </li>
          <li>
            <p>2.</p>
            <p>
              Any use by you of Third Party Services offered through the Services, or MerchX’s website is
              entirely at your own risk and discretion, and it is your responsibility to read the terms and
              conditions and/or privacy policies applicable to such Third Party Services before using them.
              In some instances, MerchX may receive a revenue share from Third Party Providers that MerchX
              recommends to you or that you otherwise engage through your use of the Services, or MerchX’s
              website.
            </p>
          </li>
          <li>
            <p>3.</p>
            <p>
              We do not provide any warranties or make representations to you with respect to Third Party
              Services. You acknowledge that MerchX has no control over Third Party Services and shall not
              be responsible or liable to you or anyone else for such Third Party Services. The availability
              of Third Party Services on MerchX’s websites, or the integration or enabling of such Third
              Party Services with the Services does not constitute or imply an endorsement, authorization,
              sponsorship, or affiliation by or with MerchX. MerchX does not guarantee the availability of
              Third Party Services and you acknowledge that MerchX may disable access to any Third Party
              Services at any time in its sole discretion and without notice to you. MerchX is not responsible
              or liable to anyone for discontinuation or suspension of access to, or disablement of, any
              Third Party Service. MerchX strongly recommends that you seek specialist advice before using
              or relying on Third Party Services, to ensure they will meet your needs. In particular, tax
              calculators should be used for reference only and not as a substitute for independent tax advice,
              when assessing the correct tax rates you should charge to your customers.
            </p>
          </li>
          <li>
            <p>4.</p>
            <p>
              If you install or enable a Third Party Service for use with the Services, you grant us permission
              to allow the applicable Third Party Provider to access your data and other Materials and to take
              any other actions as required for the interoperation of the Third Party Service with the Services,
              and any exchange of data or other Materials or other interaction between you and the Third Party
              Provider is solely between you and such Third Party Provider. MerchX is not responsible for any
              disclosure, modification or deletion of your data or other Materials, or for any corresponding
              losses or damages you may suffer, as a result of access by a Third Party Service or a Third Party
              Provider to your data or other Materials.
            </p>
          </li>
          <li>
            <p>5.</p>
            <p>
              The relationship between you and any Third Party Provider is strictly between you and such Third
              Party Provider, and MerchX is not obligated to intervene in any dispute arising between you and a
              Third Party Provider.
            </p>
          </li>
          <li>
            <p>6.</p>
            <p>
              Under no circumstances shall MerchX be liable for any direct, indirect, incidental, special,
              consequential, punitive, extraordinary, exemplary or other damages whatsoever, that result
              from any Third Party Services or your contractual relationship with any Third Party Provider.
              These limitations shall apply even if MerchX has been advised of the possibility of such damages.
              The foregoing limitations shall apply to the fullest extent permitted by applicable law.
            </p>
          </li>
          <li>
            <p>7.</p>
            <p>
              You agree to indemnify and hold us and (as applicable) our parent, subsidiaries, affiliates,
              MerchX partners, officers, directors, agents, employees, and suppliers harmless from any claim
              or demand, including reasonable attorneys’ fees, arising out of your use of a Third Party Service
              or your relationship with a Third Party Provider.
            </p>
          </li>
        </ul>
      </div>
    )
  },
  {
    id: uuid(),
    title: '15. Beta Services',
    description: (
      <div>
        <p>
          From time to time, MerchX may, in its sole discretion, invite you to use, on a trial basis,
          pre-release or beta features that are in development and not yet available to all merchants
          (“Beta Services”). Beta Services may be subject to additional terms and conditions, which
          MerchX will provide to you prior to your use of the Beta Services.
        </p>
        <p>
          Such Beta Services and all associated conversations and materials relating thereto will be
          considered MerchX Confidential Information and subject to the confidentiality provisions in this
          agreement. Without limiting the generality of the foregoing, you agree that you will not make any
          public statements or otherwise disclose your participation in the Beta Services without MerchX’s
          prior written consent. MerchX makes no representations or warranties that the Beta Services will
          function. MerchX may discontinue the Beta Services at any time in its sole discretion. MerchX will
          have no liability for any harm or damage arising out of or in connection with a Beta Service.
          The Beta Services may not work in the same way as a final version. MerchX may change or not release
          a final or commercial version of a Beta Service in our sole discretion.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: '16. DMCA Notice and Takedown Procedure',
    description: (
      <div>
        <p>
          MerchX supports the protection of intellectual property and asks MerchX merchants to do the same.
          It’s our policy to respond to all notices of alleged copyright infringement. If someone believes
          that one of our merchants is infringing their intellectual property rights, they can send a DMCA
          Notice to MerchX’s designated agent using our form. Upon receiving a DMCA Notice, we may remove or
          disable access to the Materials claimed to be a copyright infringement. Once provided with a notice
          of takedown, the merchant can reply with a counter notification using our form if they object to the
          complaint. The original complainant has 14 business days after we receive a counter notification to
          seek a court order restraining the merchant from engaging in the infringing activity, otherwise we
          restore the material. For more information, see our DMCA Policy.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: '17. Rights of Third Parties',
    description: (
      <div>
        <p>
          Save for MerchX and its affiliates, MerchX Users or anyone accessing MerchX Services pursuant to
          these Terms of Service, unless otherwise provided in these Terms of Service, no person or
          entity who is not a party to these Terms of Service shall have any right to enforce any term
          of these Terms of Service, regardless of whether such person or entity has been identified by
          name, as a member of a class or as answering a particular description. For the avoidance of
          doubt, this shall not affect the rights of any permitted assignee or transferee of these Terms.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: '18. Privacy & Data Protection',
    description: (
      <div>
        <p>
          MerchX is firmly committed to protecting the privacy of your personal information and the personal
          information of your customers. By using the Service, you acknowledge and agree that MerchX’s
          collection, usage and disclosure of this personal information is governed by our Privacy Policy.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: '19. Acceptable Use Policy (AUP)',
    description: (
      <div>
        <p>
          MerchX is a commerce platform that provides the tools and technology for merchants to
          set up an online brand and store to sell products and services on their own website,
          at physical retail locations, marketplaces and more. While we believe the free and open
          exchange of ideas and products is a key tenet of commerce, there are some activities
          that are incompatible with MerchX’s mission to make commerce better for everyone.
          This Acceptable Use Policy (“<b>AUP</b>”) describes activities that are prohibited in
          connection with your use of the Services.
        </p><br/>
        <p>
          The following activities are prohibited:
        </p><br/>
        <p>
        <b>Child exploitation: </b>You may not offer goods or services, or post or upload any materials
        that exploit or abuse children, including but not limited to images or depictions of child abuse
        or sexual abuse, or that present children in a sexual manner.
        </p><br/>
        <p>
          <b>Harassment, bullying, defamation and threats: </b>You may not offer goods or services, or
          post or upload Materials, that harass, bully, defame or threaten a specific individual.
        </p><br/>
        <p>
          <b>Hateful content: </b> You may not use the Services to promote or condone hate or violence
          against people based on race, ethnicity, color, national origin, religion, age, gender, sexual
          orientation, disability, medical condition, veteran status or other forms of discriminatory
          intolerance. You may not use the Services to promote or support organizations, platforms or
          people that: (i) promote or condone such hate; or (ii) threaten or condone violence to further a cause.
        </p><br/>
        <p>
          <b>Illegal activities: </b>You may not offer goods or services, or post or upload Materials,
          that contravene or that facilitate or promote activities that contravene, the laws of the
          jurisdictions in which you operate or do business.
        </p><br/>
        <p>
          <b>Intellectual property: </b>You may not offer goods or services, or post or upload Materials,
          that infringe on the copyright or trademarks of others.
        </p><br/>
        <p>
          <b>Malicious and deceptive practices: </b>You may not use the Services to transmit malware or host phishing
          pages. You may not perform activities or upload or distribute Materials that harm or disrupt the
          operation of the Services or other infrastructure of MerchX or others, including MerchX’s third
          party providers. You may not use the Services for deceptive commercial practices or any other
          illegal or deceptive activities.
        </p><br/>
        <p>
          <b>Personal, confidential, and protected health information: </b>You may not post or upload any
          Materials that contain personally identifiable information, sensitive personal information, or
          confidential information, such as credit card numbers, confidential national ID numbers, or
          account passwords unless you have consent from the person to whom the information belongs or
          who is otherwise authorized to provide such consent. You may not use the Services to collect,
          store, or process any protected health information subject to the Health Insurance Portability
          and Accountability Act (“HIPAA”), any applicable health privacy regulation or any other applicable
          law governing the processing, use, or disclosure of protected health information.
        </p><br/>
        <p>
          <b>Restricted Items: </b>You may not offer goods or services that are, or appear to be, firearms
          or related products.
        </p><br/>
        <p>
          <b>Self-harm: </b>You may not offer goods or services, or post or upload Materials that
          promote self-harm.
        </p><br/>
        <p>
          <b>Spam: </b>You may not use the Services to transmit unsolicited commercial
          electronic messages.
        </p><br/>
        <p>
          We may, at any time and without notice, remove any Materials, and suspend or terminate your
          Account or your access to the Services if you engage in activities that violate the letter or
          spirit of this AUP, including activities outside of your use of the Services.
        </p><br/>
        <p>
          MerchX has the right, but not the obligation, to monitor or investigate any Materials and
          your use of the Services at any time for compliance with this AUP and the MerchX Terms of
          Service, or any other agreement between you and MerchX governing your use of the Services
          (collectively, the “<b>Terms</b>”). Our determination of whether a violation of this AUP has occurred
          will be final and binding, and any action taken with respect to enforcing this AUP, including
          taking no action at all, will be at our sole discretion.
        </p><br/>
        <p>
          MerchX may modify this AUP, including the list of Restricted Items, at any time by posting a
          revised version on its site. By continuing to use the Services or access your Account after a
          revised version of the AUP has been posted, you agree to comply with the latest version of the
          AUP. In the event of a conflict between the AUP and the Terms, this AUP will take precedence,
          but only to the extent required to resolve such conflict. Capitalized terms used but not defined
          in this AUP shall have the meanings set forth in the Terms.
        </p><br/>
        <p>
          If you feel that a user of the Services has violated this AUP, please contact
          us at <a href="mailto: support@merchx.com">support@merchx.com</a>.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: '20. MerchX Shipping Terms of Service',
    description: (
      <div>
        <b>A. MerchX Shipping</b>
        <p>
          You must read, agree with and accept all of the terms and conditions contained in these Shipping
          Terms before you begin to use MerchX Shipping.
        </p><br/>
        <p>
          You agree to use MerchX Shipping in accordance with all procedures that may be provided to
          you by MerchX from time to time. If you do not agree with these procedures then you should
          not use MerchX Shipping.
        </p><br/>
        <p>
          MerchX Shipping allows you access to purchase United States Postal Service (“USPS”) postage,
          UPS Market Driver, Inc. (“UPS”) services and DHL Express (USA), Inc. (“DHL” and each of USPS,
          UPS and DHL a “Carrier” and together the “Carriers”) services to assist you with fulfilment
          for your MerchX orders. You do not need to create Carrier accounts to use MerchX Shipping.
        </p><br/>
        <b>B. Your Responsibilities</b>
        <p>
          MerchX serves only as an intermediary between you and the applicable Carrier, allowing you
          to purchase services, postage and to print shipping labels (“Labels”). You are responsible
          for shipping to your customers. MerchX is not responsible for picking up, transporting,
          delivering or otherwise dealing with your shipment.
        </p><br/>
        <p>
          Your use of USPS postage is subject to, and you are responsible for compliance with, all
          applicable
          USPS rules. See <a target="_blank" rel="noopener noreferrer" href="https://www.usps.com/ship/shipping-restrictions.htm">Shipping Restrictions</a> for
          more information about what you are and are not permitted to ship through USPS,
          and <a target="_blank" rel="noopener noreferrer" href="http://pe.usps.com/text/dmm300/dmm300_landing.htm">Mailing Standards</a> for
          packaging and labelling requirements.
        </p><br/>
        <p>
          Your use of DHL shipping is subject to, and you are responsible for compliance with,
          all applicable DHL rules.
          See <a target="_blank" rel="noopener noreferrer" href="http://www.dhl-usa.com/">DHL’s Terms and Conditions of Carriage</a> for
          the terms and conditions governing your shipment with DHL.
        </p><br/>
        <p>
          Your use of UPS shipping is subject to, and you are responsible for compliance with, all
          applicable UPS rules.
          See the <a target="_blank" rel="noopener noreferrer" href="https://www.ups.com/assets/resources/media/terms_service_us.pdf">UPS Tariff/Terms and Conditions of Service - United States</a> as well as
          the <a target="_blank" rel="noopener noreferrer" href="https://www.ups.com/assets/resources/media/UTA_with_EUR.pdf">UPS Technology Agreement</a> for
          the terms and conditions governing your shipment with UPS.
        </p><br/>
        <p>
          You are responsible for compliance with all local and international shipping and customs regulations.
        </p><br/>
        <p>
          Shipping insurance purchased through MerchX Shipping is administered by Shipsurance Insurance Services,
          Inc. and provided by Voyager Indemnity Insurance. All shipping insurance purchased through MerchX
          Shipping is subject
          to <a target="_blank" rel="noopener noreferrer" href="https://www.shipsurance.com/shopify/terms">Shipsurance’s Coverage Rules for the MerchX Shipping Insurance Program</a>. An
          insurance claim may be submitted through your MerchX admin. All insurance claims are determined by
          Shipsurance, please contact them with respect to any filed claim.
        </p><br/>
        <p>
          MerchX is not a licensed insurance producer. Depending on your location, MerchX may receive
          compensation from Shipsurance in exchange for making the option to purchase parcel insurance
          available to you. MerchX's compensation is not dependent upon whether or not you elect to
          purchase insurance
        </p><br/>
        <p>
          Use of MerchX Shipping must be associated with MerchX orders. Labels may not be transferred or sold
          to a third party. You are permitted to charge or pass through your shipping
          costs to your customers at your discretion.
        </p><br/>
        <p>
          You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of MerchX
          Shipping, use MerchX Shipping, or access MerchX Shipping without the express written permission
          of MerchX. You may not use MerchX Shipping for any illegal or unauthorized purpose. You may
          not, in the use of MerchX Shipping, violate any laws in your jurisdiction or the laws of
          Canada or the Province of Ontario.
        </p><br/>
        <p>
          MerchX will not be responsible for any local customs charges, import taxes or duties or any
          similar charges related to your shipments. You must figure out whether any of these charges
          will become due, and arrange for their payment. If any such charges become due as a result
          of your use of MerchX Shipping and are charged to MerchX by any competent authority, you agree
          to reimburse MerchX fully in respect of the same within 7 days of our demand.
        </p><br/>
        <p>
          MerchX PROVIDES ACCESS TO MerchX SHIPPING ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE MAXIMUM
          EXTENT PERMITTED BY APPLICABLE LAW, MerchX MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND
          REGARDING MerchX SHIPPING. MerchX DISCLAIMS ALL CONDITIONS AND WARRANTIES, EITHER EXPRESS OR
          IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR
          A PARTICULAR PURPOSE, OR WARRANTIES THAT MAY ARISE BY USAGE OF TRADE, COURSE OF DEALING, OR
          COURSE OF PERFORMANCE. MerchX SHALL HAVE NO LIABILITY WHATSOEVER ARISING FROM OR RELATING TO
          YOUR USE OF MerchX SHIPPING, INCLUDING BUT NOT LIMITED TO ANY DIRECT, SPECIAL, EXEMPLARY,
          PUNITIVE, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO DAMAGES
          FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES OF ANY KIND, WHETHER BASED
          IN CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH
          MerchX SHIPPING. IN NO EVENT SHALL MerchX'S ENTIRE LIABILITY TO YOU EXCEED THE PRICE PAID BY
          YOU FOR MerchX SHIPPING.
        </p><br/>
        <p>
          You agree to indemnify and hold MerchX and (as applicable) our parent, subsidiaries, affiliates,
          MerchX partners, officers, directors, agents, and employees, harmless from any liability, expense,
          loss, claim or demand, including reasonable attorneys’ fees, arising out of or in any way connected
          with any unauthorized use of MerchX Shipping, your breach of these Shipping Terms or the documents
          it incorporates by reference, your violation of any law or the rights of a third party, any data
          uploaded by you or on your behalf, or any of your actions or omissions related to MerchX Shipping.
        </p><br/>
        <p>
          Any use by you of MerchX Shipping is entirely at your own risk and discretion. You should ensure
          that you are familiar with and approve the terms on which shipping services are provided by
          the <a target="_blank" rel="noopener noreferrer" href="https://www.usps.com/">USPS</a>, <a target="_blank" rel="noopener noreferrer" href="https://www.ups.com/media/en/daily_rates.pdf">UPS</a> 
          and <a target="_blank" rel="noopener noreferrer" href="http://www.dhl-usa.com/">DHL</a>.
        </p><br/>
        <b>C. UPS Carrier Account</b>
        <p>
          Certain MerchX subscription plans allow you to connect your own UPS account with MerchX Shipping
          to offer your negotiated UPS rates and purchase labels using your own UPS rates in MerchX Shipping
          (“Your UPS Carrier Account”). If you add a UPS Carrier Account to MerchX Shipping, all fees,
          credits, refunds and charges using Your UPS Carrier Account will be between yourself and UPS.
          You are responsible to UPS for all purchases made using Your UPS Carrier Account. Rates surfaced
          in checkout and for label purchases when you use Your UPS Carrier Account are estimates only and
          may not accurately reflect the rates that you have obtained from UPS. MerchX is not responsible
          for the accuracy of rates at checkout or for label purchases where you use Your UPS Carrier
          Account. MerchX can assist in connecting Your UPS Carrier Account with MerchX Shipping, but
          MerchX is not responsible for support requirements that relate to Your UPS Carrier Account,
          please contact UPS if you have any questions or issues with Your USP Carrier Account.
        </p><br/>
        <b>D. Fees</b>
        <p>
          You agree that you will pay the applicable fees for all purchases (other than those made through
          Your UPS Carrier Account which will be billed directly by UPS) completed through MerchX Shipping
          (the “Fees”). All Fees will be added to your next MerchX Shipping invoice. You are responsible
          for any applicable Taxes (as defined in the MerchX Terms of Service) associated with the Fees.
        </p><br/>
        <p>
          MerchX will, from time to time, charge your credit card for the aggregate Fees you have incurred
          on MerchX Shipping. If your credit card payment is declined or if we are otherwise not able to
          collect payment from you for the invoiced Fees, you will not be able to purchase any postage or
          services through MerchX Shipping, including Labels, until such Fees are paid.
        </p><br/>
        <p>
          You authorize MerchX to charge your credit card currently used to pay for your Account for any
          Fees incurred in relation to MerchX Shipping, in accordance with these Shipping Terms. You will
          be responsible for payment in the event your credit card is declined or we are otherwise unable
          to collect payment from you. An invoice will appear on the Account page of your MerchX
          administration console. Users have approximately two weeks to bring up and settle any issues
          with the billing.
        </p><br/>
        <p>
          Any Fee refunds for unused Labels must be requested from within the associated order within
          thirty days after Label creation. Any credits or refunds applicable to MerchX Shipping (other
          than through Your UPS Carrier Account) will be applied to your Account and may only be applied
          to the purchase of other services or postage through MerchX Shipping.
        </p><br/>
        <p>
          For Labels purchased through MerchX Shipping (other than through Your UPS Carrier Account),
          the associated shipment may be audited by the applicable Carrier for weight, dimensions, mail
          class, package type and Zip or Postal Code. If an audit reveals that the Label you’ve purchased
          does not accurately reflect, additional Fees or credits may be applied to your Account. You will
          be able to review any adjustments on the Orders and Invoices pages of your MerchX administration
          console (not applicable to adjustments on Your UPS Carrier Account).
        </p><br/>
        <p>
          If a shipment is determined to be undeliverable by a Carrier you may be contacted by email to
          determine whether to have the shipment returned or abandoned. You are responsible for all Fees
          associated with returned or abandoned shipments, regardless of whether you have approved the
          return or abandonment of the shipment.
        </p><br/>
        <b>E. General Terms</b>
        <p>
          MerchX reserves the right to modify, suspend or terminate MerchX Shipping or your ability
          to use MerchX Shipping at any time, for any reason, either with or without notice to you.
        </p><br/>
        <p>
          You acknowledge and agree that your use of MerchX Shipping, including information
          transmitted to or stored by MerchX, is governed by MerchX’s privacy policy.
        </p><br/>
        <p>
          These Shipping Terms shall be governed by and interpreted in accordance with the laws of
          the Province of North Carolina and the laws of The United States applicable therein, without
          regard to principles of conflicts of laws. The parties irrevocably and unconditionally submit
          to the exclusive jurisdiction of the courts of the Province of North Carolina with respect to
          any dispute or claim arising out of or in connection with these Shipping Terms. The United
          Nations Convention on Contracts for the International Sale of Goods will not apply to these
          Shipping Terms and is hereby expressly excluded.
        </p><br/>
        <p>
          You acknowledge and agree that MerchX may amend these Shipping Terms at any time by posting
          the relevant amended and restated Shipping Terms and such amendments to the Shipping Terms are
          effective as of the date of posting. Your continued use of MerchX Shipping after the amended
          Shipping Terms are posted constitutes your agreement to, and acceptance of, the amended Shipping
          Terms. If you do not agree to any changes to the Shipping Terms, do not continue to use the
          Shipping Service.
        </p><br/>
        <p>
          The failure of MerchX to exercise or enforce any right or provision of these Shipping Terms
          shall not constitute a waiver of such right or provision.
        </p><br/>
        <p>
          If a court finds any portion of these Shipping Terms to be unenforceable or invalid, that portion
          of the Shipping Terms will be enforced to the fullest extent permitted by applicable law and
          the remainder of the Shipping Terms will remain valid and enforceable.
        </p><br/>
        <p>
          Questions about these Shipping Terms should be sent
          to <a href="mailto: support@merchx.com">support@merchx.com</a>
        </p>
      </div>
    )
  }

];