import React from 'react';
import {Link} from 'react-router-dom';
import './footer.scss';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer_links-line">
        <Link className="footer_link" to="/contact">Contact</Link>
        {/*<Link className="footer_link" to="/faq">FAQ’s</Link>*/}
        <Link className="footer_link" to="/terms">Terms</Link>
        <Link className="footer_link" to="/privacy">Privacy Policy</Link>
        <Link className="footer_link" to="/dmca">DMCA Policy</Link>
      </div>
    </div>
  );
};

export default Footer;