import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './header.scss';
import { LogoLight } from 'components';

const propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string
};

const Header = ({
  title = 'MerchX',
  subTitle = ''
}) => {
  return (
    <div className="header">
      <Link to="/">
        <LogoLight />
      </Link>
      <div className="header_wrapper">
        <div className="header_heading">
          {title}
        </div>
        <div className="header_heading">
          {subTitle}
        </div>
      </div>
    </div>
  );
};

Header.propTypes = propTypes;

export default Header;