import React, { useRef, useState } from 'react';
import './home.scss';
import payment from 'assets/images/payment.png';
import iconBox from 'assets/images/icon_box.png';
import iconPrice from 'assets/images/icon_price.png';
import iconPeople from 'assets/images/icon_people.png';
import iconCamp1 from 'assets/images/brands/camp_1.png';
import iconCamp2 from 'assets/images/brands/camp_2.png';
import iconCamp3 from 'assets/images/brands/camp_3.png';
import iconCamp4 from 'assets/images/brands/camp_4.png';
import iconCamp5 from 'assets/images/brands/camp_5.png';
import iconCamp6 from 'assets/images/brands/camp_6.png';
import iconCamp7 from 'assets/images/brands/camp_7.png';
import { LogoDark, Footer, HomeButton, Loader } from 'components';

import { sendgridSend } from 'utils/sendgridHelper';

const Home = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [emailError, setEmailError] = useState(false);
  const emailField = useRef(null);

  const goToEmailField = () => {
    if (emailField.current) {
      window.scrollTo(0, emailField.current.offsetTop - 100);
      emailField.current.focus();
    }
  };

  const onChangeEmail = (event) => {
    if (emailError) {
      setEmailError(false);
    }
    setEmailValue(event.target.value);
  };

  const sendEmail = () => {
    if (emailValue.search(/.+@.+\..+/i) === -1) {
      setEmailError(true);
    } else {
      document.body.style.overflow = 'hidden';
      setIsLoader(true);

      sendgridSend({
        subject: 'Sign Up from merchx.com',
        body: `Application to sign up ${emailValue}`
      })
        .then(() => {
          document.body.style.overflow = 'visible';
          setIsLoader(false);
          setEmailValue('');
        })
        .catch(error => {
          console.error(error);
          document.body.style.overflow = 'visible';
          setIsLoader(false);
        })
    }
  };

  return (
    <div className="home">
      {isLoader && <Loader />}
      <div className="home_wrapper">
        <LogoDark />

        <div className="home_heading-bold">
          Build brands with MerchX
        </div>
        <div className="home_heading">
          Start building online brands with powerful ecomm tools
        </div>

        <div className="home_top">
          <div className="home_form">
            <input
              className={`home_form-input ${emailError ? ' home_form-input_error' : ''}`}
              placeholder="Enter your email address"
              ref={emailField}
              value={emailValue}
              onChange={onChangeEmail}
            />
            <HomeButton onClick={sendEmail} />
          </div>
          <div className="home_heading-descr">
            Apply for MerchX beta before the general release. All beta testers are selected at random,
            limited slots available.
          </div>
          <img className="home_payment-img" src={payment} alt=""/>
        </div>

        <div className="home_features">
          <div className="home_features-item">
            <img className="home_features-icon" src={iconBox} alt="" />
            <div className="home_features-name">
              Build your brand through responsive and fully customizable templates
            </div>
            <div className="home_features-descr">
              Choose from preset templates or completely reinvent your user experience with custom css and
              html capabilities. Design your website from its layout and UI with no design skills needed.
            </div>
          </div>
          <div className="home_features-item">
            <img className="home_features-icon" src={iconPrice} alt="" />
            <div className="home_features-name">
              Fully integrated manufacturing and fulfillment capabilities
            </div>
            <div className="home_features-descr">
              We support in-house manufacturing and fulfillment for all orders. Start selling online without
              the need to set up dropshipping from third party vendors. All products manufactured and shipped
              within the United States.
            </div>
          </div>
          <div className="home_features-item">
            <img className="home_features-icon" src={iconPeople} alt="" />
            <div className="home_features-name">
              Trusted by over 1,000 brands and counting
            </div>
            <div className="home_features-descr">
              MerchX has assisted independent business in launching over 1,000 brands. Fully integrated customer
              support allows you to focus on what matters, your customers and experience.
            </div>
          </div>
        </div>

        <div className="home_brands">
          <div className="home_brands-line">
            <img className="home_brands-icon" src={iconCamp1} alt="" />
            <img className="home_brands-icon" src={iconCamp2} alt="" />
            <img className="home_brands-icon" src={iconCamp3} alt="" />
            <img className="home_brands-icon" src={iconCamp4} alt="" />
            <img className="home_brands-icon" src={iconCamp5} alt="" />
            <img className="home_brands-icon" src={iconCamp6} alt="" />
            <img className="home_brands-icon" src={iconCamp7} alt="" />
          </div>
          <div className="home_brands-descr">
            Over 1,000 brands built on MerchX
          </div>
        </div>

        <div className="home_end">
          <div className="home_end-quote">
            “MerchX has completely changed how we approach online marketing and branding.”
          </div>
          <div className="home_end-author">
            Key Baulme, Country Music Forever
          </div>
          <HomeButton onClick={goToEmailField} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;