import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Home, Contact, Faq, Terms, Privacy, DMCA } from './pages';
import './assets/styles/sass/styles.scss';

const Routing = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/contact" component={Contact} />
      <Route path="/faq" component={Faq} />
      <Route path="/terms" component={Terms} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/dmca" component={DMCA} />

    </Switch>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <Routing />
    </BrowserRouter>
  );
};

export default App;