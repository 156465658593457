import React from 'react';
import { Footer, Header, Accordion } from 'components';
import data from './data';

const DMCA = () => {
  return (
    <div className="common_page">
      <Header subTitle="DMCA Policy" />

      <div className="common_page-wrapper">
        <Accordion data={ data } />
      </div>

      <Footer />
    </div>
  );
};

export default DMCA;