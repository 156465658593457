import React from 'react';
import uuid from 'uuid';

export default [
  {
    id: uuid(),
    title: 'What is MerchX\'s copyright and DMCA policy?',
    description: (
      <div>
        <p>
          MerchX supports the protection of intellectual property and requires their users to do the same.
          Our policy is to respond to notices of alleged copyright infringement regarding copyright in
          accordance with the <a target="_blank" rel="noopener noreferrer" href="http://lcweb.loc.gov/copyright/legislation/dmca.pdf">
          Digital Millennium Copyright Act of 1998.</a>
        </p><br/>
        <p>
          All MerchX users of any part are expected to comply with applicable copyright laws. MerchX
          will remove all material(s) that is the subject of a compliant DMCA takedown
          notice. <a target="_blank" rel="noopener noreferrer" href="http://www.copyright.gov/title17/92chap5.html#512">Section 512</a> of
          the DMCA provides the rules and guidelines for reporting copyright infringement as well as for filing
          a counter-notification.
        </p><br/>
        <p>
          If you (person or company) believe your copyright is being misused or infringed, you can report
          the violation to our copyright agent: <a href="mailto:copyright@merchx.com">copyright@merchx.com</a>
        </p><br/>
        <p>
          If your content or post was the subject of a takedown notice, you can similarly submit a response or
          counter-notice to defend your use of the content.
        </p><br/>
        <p>
          In applicable circumstances, user accounts that repeatedly violate the copyright laws and infringe on
          the intellectual property of others may result in termination user accounts.
        </p><br/>
        <p>
          MerchX reserves the right to terminate user accounts and pages with the first violation of
          copyright infringement.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'How to report a copyright infringement/violation',
    description: (
      <div>
        <p>
          When uncertain about whether a user has posted or used material that violates the copyright policy,
          it is recommended to consult with an attorney before filing a DMCA notification.
        </p><br/>
        <p>
          Misrepresenting all products, links, or other such materials which infringes on your copyright may
          subject you to liability for damages, including costs and attorneys' fees incurred by Project
          Creators or other parties.
        </p><br/>
        <p>
          If you believe that your copyright is being infringed, please email us at <a href="mailto:copyright@merchx.com">copyright@merchx.com</a>. Your
        </p><br/>
        <p>
          Your claim must include the following information (all information must be submitted in English):
        </p>
        <ul>
          <li>
            Links to identify your original work pertaining to the DMCA claim (not the work present on MerchX);
          </li>
          <li>
            Descriptions of the copyrighted work that you claim has been infringed;
          </li>
          <li>
            Links to the allegedly infringing work present for the MerchX hosted store;
          </li>
          <li>
            Description of where the material that you claim is infringing is located on the Site, sufficient
            for MerchX to locate the material;
          </li>
          <li>
            Your name, address, telephone number, and email address;
          </li>
          <li>
            A declaration by you that you understand that under 17 U.S.C § 512(f) you may be liable for any
            damages, including costs and attorneys' fees, if you knowingly and materially misrepresent that
            reported material or activity is infringing;
          </li>
          <li>
            A declaration by you that you have a good faith belief that the disputed use is not authorized
            by the copyright owner, its agent, or the law; and
          </li>
          <li>
            A declaration by you that the information in your notice is accurate and, under penalty of
            perjury, that you are the copyright owner or authorized to act on the copyright owner's behalf.
          </li>
          <li>
            A physical or electronic signature.
          </li>
        </ul><br/>
        <p>
          Submitting your notification via email is the fastest way to file a claim, but you may also send
          the claim with its required information to our copyright agent at the following address:
        </p>
        <p>
          MerchX LLC
        </p>
        <p>
          Attn: Copyright Agent
        </p>
        <p>
          PO Box 411
        </p>
        <p>
          Burlington, NC 27215, United States
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'How to file a Counter Notice',
    description: (
      <div>
        <p>
          Any merchant that receives a DMCA takedown notice may submit a counter notification pursuant
          to sections 512(g)(2) and (3) of the US Copyright Act.
        </p><br/>
        <p>
          If you believe that your work has been removed or disabled erroneously or because of misidentification,
          you may submit a counter notification to MerchX by emailing us
          at <a href="mailto:copyright@merchx.com">copyright@merchx.com</a>.
        </p>
        <p>
          Your counter notification email must contain the following information (it is your responsibility
          to confirm these requirements with your legal counsel or see
          the <a target="_blank" rel="noopener noreferrer" href="http://www.copyright.gov/title17/92chap5.html#512">U.S. Copyright Act, 17 U.S.C. §512(g)(3)</a>for
          more information.
        </p><br/>
        <p>
          Note that all information must be submitted in English:
        </p>
        <p>
          1. Identification of the material that has been removed or to which access has been disabled and the
          location at which the material appeared before it was removed or access to it was disabled;
        </p><br/>
        <p>
          2. Your name, address, and telephone number;
        </p><br/>
        <p>
          3. The following statement: "I consent to the jurisdiction of Federal District Court for the judicial
          district in which my address is located or, if my address is located outside of the United States,
          I consent to the jurisdiction of the Federal District Court for the District of Delaware";
        </p><br/>
        <p>
          4. The following statement: "I will accept service of process from the party who submitted the DMCA
          Infringement Notice or its agent";
        </p><br/>
        <p>
          5. The following statement: "I swear, under penalty of perjury, that I have a good faith belief
          that the affected material was removed or disabled as a result of a mistake or misidentification
          of the material to be removed or disabled and that I am the merchant subject to the DMCA Takedown
          Notice or I am authorised to act on behalf of that merchant.";
        </p><br/>
        <p>
          6. The following statement: "I acknowledge that a copy of this counter notification, including my
          full name and contact information, will be provided to the party that submitted the DMCA Takedown
          Notice."; and
        </p><br/>
        <p>
          7. A physical or electronic signature.
        </p><br/>
        <p>
          Please note: You will be liable for damages (including costs and attorneys’ fees) if you materially
          misrepresent that a product or activity is not infringing the copyrights of others or that the material
          or activity was removed or disabled by mistake or misidentification. Accordingly, if you are not sure
          whether certain material infringes the copyrights of others or that the material or activity was
          removed or disabled by mistake or misidentification, we suggest that you first contact a lawyer.
        </p><br/>
        <p>
          This information can also be mailed to MerchX's copyright agent at the following address:
        </p>
        <p>
          MerchX LLC
        </p>
        <p>
          Attn: Copyright Agent
        </p>
        <p>
          PO Box 411
        </p>
        <p>
          Burlington, NC 27215, United States
        </p><br/>
        <p>
          Under the Copyright Act, any person who knowingly materially misrepresents that material was
          removed or disabled by mistake or misidentification may be subject to liability.
        </p><br/>
        <p>
          Once MerchX receives a counter notification, we forward it to the copyright owner that made
          the claim. If we do not receive a notice replying to the documentation presented, within 10
          business days after forwarding said counter-notification, that the owner (that made the claim)
          has filed an action seeking a court order to prevent further or continued infringement, MerchX
          may reinstate the content in question.
        </p><br/>
        <p>
          MerchX also reserves the right to forward all materials received (including full contact details)
          to the opposing party in the DMCA dispute.
        </p>
      </div>
    )
  },

];
