import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './accordion.scss';

const propTypes = {
  data: PropTypes.array,
  category: PropTypes.string,
  onFirstBlockHandler: PropTypes.func,
  resetCategory: PropTypes.func
};

const Accordion = ({ data = [], category = '', onFirstBlockHandler = () => {}, resetCategory = () => {} }) => {
  const [activeIds, setActiveId] = useState([]);

  useEffect(() => {
    if (!!category) {
      const categoryIds = [];
      let idOfBlock = null;
      data.forEach(item => {
        if (item.category === category) {
          categoryIds.push(item.id);
          if (!idOfBlock) {
            idOfBlock = item.id;
          }
        }
      });
      if (!!idOfBlock) {
        const firstBlockInOrder = document.getElementById(idOfBlock);
        onFirstBlockHandler(firstBlockInOrder);
      }
      setActiveId(categoryIds);
    }
    resetCategory(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  return (
    <div className="accordion">
      {data.map(item => (
        <div className="accordion__block" key={item.id} id={item.id}>
          <div
            className={'accordion__item' + (activeIds.find(id => id === item.id) ? ' accordion__item_active' : '')}
            onClick={() => setActiveId(activeIds.find(id => id === item.id) ? [] : [item.id])}
          >
            <p className="accordion__item-title">{item.title}</p>
            <p className="accordion__item-button">
              {activeIds.find(id => id === item.id) ? '-' : '+'}
            </p>
          </div>
          <div
            className={'accordion__item-description' + (activeIds.find(id => id === item.id) ? ' accordion__item-description_active' : '')}
          >
            {item.description}
          </div>
        </div>
      ))}
    </div>
  )
};

Accordion.propTypes = propTypes;

export default Accordion;
