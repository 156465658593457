import React from 'react';
import { Footer, Header, Accordion } from 'components';
import data from './data';

const Terms = () => {
  return (
    <div className="common_page">
      <Header subTitle="Terms of Service" />

      <div className="common_page-wrapper">
        <div className="common_page-text">
          By signing up for a MerchX Account or by using any MerchX Services (as defined below), you are
          agreeing to be bound by the following terms and conditions (the “Terms of Service”).
        </div>
        <div className="common_page-text">
          As used in these Terms of Service, “we”, “us” and “MerchX” means the applicable MerchX Contracting
          Party (as defined in Section 4 below).
        </div>
        <div className="common_page-text">
          The services offered by MerchX under the Terms of Service include various products and services to
          help you sell goods and services to buyers online (“Online Services”). Any such services offered by
          MerchX are referred to in these Terms of Services as the “Services”.
        </div>
        <div className="common_page-text">
          Any new features and tools which are added to the current Services shall be also subject to the Terms
          of Service. You can review the current version of the Terms of Service at any time on our website.
          MerchX reserves the right to update and change the Terms of Service by posting updates and changes to
          the MerchX website. It is advised to check the Terms of Service for any updates or changes that may
          impact you. and if you do not accept such amendments, you must cease using the Services.
        </div>
        <div className="common_page-text">
          You must read, agree with and accept all of the terms and conditions contained or expressly referenced
          in these Terms of Service, before you may sign up for a MerchX Account or use any MerchX Service.
        </div>


        <Accordion data={data} />
      </div>

      <Footer />
    </div>
  );
};

export default Terms;

