import React from 'react';
import { Link } from 'react-router-dom';
import logoLight from 'assets/images/logo_light.png';
import './logo_light.scss';

const LogoLight = () => {
  return (
    <Link to="/">
      <img className="logo_light" src={logoLight} alt="" />
    </Link>
  );
};

export default LogoLight;