import React, { useState } from 'react';
import { Footer, Header, Accordion } from 'components';
import data from './data';

const Faq = () => {
  const [category, setCategory] = useState(null);
  const [lastShownBlock, setLastShownBlock] = useState(null);

  const lastBlockHandler = el => {
    setLastShownBlock(el);
    window.scrollTo(0, el.offsetTop);
  };

  const onCategorySet = (cat) => {
    setCategory(cat);
    if (!!lastShownBlock) {
      window.scrollTo(0, lastShownBlock.offsetTop);
    }
  };

  return (
    <div className="common_page">
      <Header subTitle="FAQ's" />

      <div className="common_page-wrapper">
        <div className="common_page-text">
          Purchasing a product is the beginning of a long-lasting relationship between us
          and our customer. And as a customer, you'll receive nothing but the best service
          from us. That's why if you have any questions or requests, we're always happy to
          help.
        </div>
        <div className="common_page-text">
          You can contact our customer support team by emailing us at <a href="mailto: support@merchx.com" className="common_page-link">support@merchx.com</a>
        </div>

        <div className="common_page-text">
          In the meantime, we hope that our FAQ section below can help answer any questions you might have.
        </div><br/>
        <p className="common_page-link" onClick={() => onCategorySet('about_us')}>About Us</p>
        <p className="common_page-link" onClick={() => onCategorySet('product_inq')}>Product Inquiries</p>
        <p className="common_page-link" onClick={() => onCategorySet('placing_ord')}>Placing An Order</p>
        <p className="common_page-link" onClick={() => onCategorySet('order_canc')}>Order Changes & Cancellations</p>
        <p className="common_page-link" onClick={() => onCategorySet('deliv_inf')}>Delivery Information</p>
        <p className="common_page-link" onClick={() => onCategorySet('our_comm')}>Our Fan Community</p>

        <Accordion
          data={data}
          category={category}
          onFirstBlockHandler={lastBlockHandler}
          resetCategory={setCategory}
        />
      </div>

      <Footer />
    </div>
  );
};

export default Faq;