import React from 'react';
import uuid from 'uuid';

export default [
  {
    id: uuid(),
    title: 'PERSONAL INFORMATION WE COLLECT',
    description: (
      <div>
        <p>
          When you visit the Site, we automatically collect certain information about your device,
          including information about your web browser, IP address, time zone, and some of the cookies
          that are installed on your device. Additionally, as you browse the Site, we collect information
          about the individual web pages or products that you view, what websites or search terms referred
          you to the Site, and information about how you interact with the Site. We refer to this
          automatically-collected information as “Device Information.”
        </p><br/>
        <p>
          We collect Device Information using the following technologies:
        </p>
        <p>
          - “Cookies” are data files that are placed on your device or computer and often include an
          anonymous unique identifier. For more information about cookies, and how to disable cookies,
          visit http://www.allaboutcookies.org.
        </p>
        <p>
          - “Log files” track actions occurring on the Site, and collect data including your IP address,
          browser type, Internet service provider, referring/exit pages, and date/time stamps.
        </p>
        <p>
          - “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how
          you browse the Site.
        </p>
        <p>
          Additionally when you make a purchase or attempt to make a purchase through the Site, we collect
          certain information from you, including your name, billing address, shipping address, payment
          information including credit card numbers, debit card numbers, email address, and phone number.
          We refer to this information as “Order Information.”
        </p><br/>
        <p>
          When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device
          Information and Order Information.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'HOW DO WE USE YOUR PERSONAL INFORMATION?',
    description: (
      <div>
        <p>
          We use the Order Information that we collect generally to fulfill any orders placed through the Site
          (including processing your payment information, arranging for shipping, and providing you with invoices
          and/or order confirmations).  Additionally, we use this Order Information to: Communicate with you;
          Screen our orders for potential risk or fraud; and When in line with the preferences you have shared
          with us, provide you with information or advertising relating to our products or services.
        </p><br/>
        <p>
          We use the Device Information that we collect to help us screen for potential risk and fraud (in
          particular, your IP address), and more generally to improve and optimize our Site (for example,
          by generating analytics about how our customers browse and interact with the Site, and to assess
          the success of our marketing and advertising campaigns).
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'SHARING YOUR PERSONAL INFORMATION',
    description: (
      <div>
        <p>
          We share your Personal Information with third parties to help us use your Personal Information,
          as described above.  For example, we use MerchX to power our online store. We also use Google
          Analytics to help us understand how our customers use the Site--you can read more about how Google
          uses your Personal Information here:<br/>
          <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/intl/en/policies/privacy/">https://www.google.com/intl/en/policies/privacy/</a>. You can
          also opt-out of Google Analytics here: <a target="_blank" rel="noopener noreferrer" href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
        </p><br/>
        <p>
          Finally, we may also share your Personal Information to comply with applicable laws and regulations,
          to respond to a subpoena, a search warrant or other lawful request for information we receive, or
          to otherwise protect our rights.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'BEHAVIORAL ADVERTISING',
    description: (
      <div>
        <p>
          As described above, we use your Personal Information to provide you with targeted advertisements
          or marketing communications we believe may be of interest to you.  For more information about how
          targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational
          page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
        </p>
        <p>
          You can opt out of targeted advertising by:
        </p>
        <p>
          FACEBOOK - https://www.facebook.com/settings/?tab=ads
        </p>
        <p>
          GOOGLE - https://www.google.com/settings/ads/anonymous
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'MerchX PARTNERS: ADVERTISING AND AFFILIATE LINKS',
    description: (
      <div>
        <p>
          MerchX works with businesses and organizations referred to as “partners”. Our
          advertising partners and our affiliate links partners may collect personal data from
          you for their own purposes.
        </p><br/>
        <ul>
          <li>
            Advertising partners are third party advertisement platforms, agencies, technology
            vendors and research firms.
          </li>
          <li>
            Affiliate links partners pay us when you purchase goods or services from them or another
            third party after clicking on an affiliate link in our content.
          </li>
        </ul><br/>
        <p>
          Our partners may use third party cookies to serve advertisements to you with tailored (or personalized)
          advertisements when you are on our sites and apps and when you visit third party sites. They may also
          use cookies to measure traffic on our pages (for example to track ad views).
        </p><br/>
        <p>
          We are not responsible for the privacy practices of these third parties and it is your responsibility
          to read their privacy notices so that you understand how they process information about you.
        </p><br/>
        <p>
          <b>MerchX LLC is Not Responsible for Linked Sites:</b>
        </p>
        <p>
          We are not responsible/liable for the services or content from third party links or websites that may
          be linked to our site and its content. We do not control such services, you acknowledge and agree that
          MerchX LLC is not responsible for such external services or goods, that we do not endorse and are not
          responsible or liable for any content, accuracy, quality, advertising, products or other materials on or
          available from such third party links or websites. You also agree that we shall not be responsible or
          liable, directly or indirectly, for any damage or loss caused (or alleged) by or in connection with
          the use of or reliance on any content, goods or services available on or through such services.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'DO NOT TRACK',
    description: (
      <div>
        <p>
          Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track
          signal from your browser.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'YOUR RIGHTS',
    description: (
      <div>
        <p>
          If you are a European resident, you have the right to access personal information we hold about you and
          to ask that your personal information be corrected, updated, or deleted. If you would like to exercise
          this right, please contact us through the contact information below.
        </p>
        <p>
          Additionally, if you are a European resident we note that we are processing your information in order to
          fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise
          to pursue our legitimate business interests listed above.  Additionally, please note that your information
          will be transferred outside of Europe, including to Canada and the United States.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'DATA RETENTION',
    description: (
      <div>
        <p>
          When you place an order through the Site, we will maintain your Order Information for our records unless
          and until you ask us to delete this information.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'MINORS',
    description: (
      <div>
        <p>
          The Site is not intended for individuals under the age of 13.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'CHANGES',
    description: (
      <div>
        <p>
          We may update this privacy policy from time to time in order to reflect, for example, changes to our
          practices or for other operational, legal or regulatory reasons.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'CONTACT US',
    description: (
      <div>
        <p>
          For more information about our privacy practices, if you have questions, or if you would like to make a
          complaint, please contact us by e-mail at <a href="mailto: support@merchx.com">support@merchx.com</a> or by mail using the details provided below:
        </p>
        <p>
          PO Box 411 , Burlington, NC, 27215, United States
        </p>
      </div>
    )
  },

];
