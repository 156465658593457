import React from 'react';
import uuid from 'uuid';

export default [
  {
    id: uuid(),
    title: 'What is your mission?',
    category: 'about_us',
    description: (
      <div>
        <p>
          Our mission doesn't end with us delivering orders to customers. We want
          to create a robust community of enthusiasts and self started brands. We
          want to create a platform where all fans can communicate and relate to one
          another easily. We want to help you create friendships with fellow enthusiasts
          all over the world!
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'What do you offer?',
    category: 'about_us',
    description: (
      <div>
        <p>
          At the moment, we offer brands and small companies the tools to reach customers
          just like you. We make sure to handle all customer concerns and fulfillment in order
          to maintain a standard of quality across the board. Any issues that you may face will
          be addressed by us and we will relay all concerns to the respective brands/persons.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'Is my payment method secure with you?',
    category: 'about_us',
    description: (
      <div>
        <p>
          Yes, your payment method is absolutely secure with us. Brands and companies don't store
          any of your card information and we will never share your personal information with anyone else.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'Is your offer available at my location?',
    category: 'about_us',
    description: (
      <div>
        <p>
          Yes, we're proud to say that we ship worldwide. However, if you don't see your country
          available on our site, please let us know!
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'How are your custom products created?',
    category: 'product_inq',
    description: (
      <div>
        <p>
          Our custom products are created through a rigorous screen printing process. Each
          product is custom printed meaning our t-shirts are never mass printed. This ensures that
          each t-shirt is created with the best care for the best quality.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'What are your t-shirts made of?',
    category: 'product_inq',
    description: (
      <div>
        <p>
          Our t-shirts are made of 100% premium cotton material. Making the fabric both soft and breathable.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'How is the fit of your t-shirts?',
    category: 'product_inq',
    description: (
      <div>
        <p>
          Our t-shirts run in unisex sizes and are true to size. However, we always recommend to size
          up when you order for a more comfortable and relaxed fit.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'Why do I have to pay when the t-shirt is free?',
    category: 'placing_ord',
    description: (
      <div>
        <p>
          Your payment method is required to cover the shipping costs so we can deliver your t-shirt to you!
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'Which payment methods do you accept?',
    category: 'placing_ord',
    description: (
      <div>
        <p>
          We currently accept all Visa and Mastercard debit/credit cards, as well as prepaid cards that
          can be purchased at your local convenience store.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'My order isn\'t going through, what can I do?',
    category: 'placing_ord',
    description: (
      <div>
        <p>
          Please contact our friendly customer support team and we'd be happy to look over any
          issues with you and help you resolve them.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'How do I cancel my order?',
    category: 'order_canc',
    description: (
      <div>
        <p>
          You can cancel your order with us by submitting a cancellation request via email. However,
          please be advised that since we immediately proceed to manufacture custom orders, the item(s)
          in your order may have already been printed and packed for delivery.
        </p><br/>
        <p>
          In the event that your order has already shipped out for delivery upon initial cancellation request,
          please contact us and we'll provide you further instructions on how to return your order for a refund.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'Can I change my order for a different design/size?',
    category: 'order_canc',
    description: (
      <div>
        <p>
          Yes, we can change the item(s) in your order as long as your order hasn't been processed and sent
          out for delivery. However, in the event your order has been processed, please contact us and we
          will guide you through how you can change the item(s) in your order.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'Can I exchange the t-shirt for a different design/size once I received my order?',
    category: 'order_canc',
    description: (
      <div>
        <p>
          You may exchange your t-shirt for a different design and/or size granted it is in a new condition
          and it is unworn. However, you must cover the fee for delivery back to our warehouse. To exchange
          your order, please contact us beforehand for detailed, step-by-step instructions.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'When can I expect my order to arrive?',
    category: 'deliv_inf',
    description: (
      <div>
        <p>
          Upon shipment date, your order will arrive in 7 - 15 days (USA orders) or 15 - 25 days (international
          orders). However, please be aware that unforeseen circumstances such as weather conditions can
          further delay the delivery of your order.
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'Which courier(s) do you use for shipment?',
    category: 'deliv_inf',
    description: (
      <div>
        <p>
          We use <b>USPS</b> for USA orders and <b>DHL</b> for all international orders
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'I have my tracking number, how do I track my order?',
    category: 'deliv_inf',
    description: (
      <div>
        <p>Track your order easily with through these links:</p>
        <p>
          USA orders: Track your order <a href="https://tools.usps.com/go/TrackConfirmAction?tLabels=" rel="noopener noreferrer" target="_blank">HERE</a>
        </p>
        <p>
          International orders: Track your order <a href="https://webtrack.dhlglobalmail.com/" rel="noopener noreferrer" target="_blank">HERE</a>
        </p>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'How do I become a part of this community?',
    category: 'our_comm',
    description: (
      <div>
        <div>
          <p>
            If you have placed an order with us, you'll be automatically receiving email updates from MerchX,
            on how you can take part of this community and meet others just like you.
          </p><br/>
          <p>
            However, if you haven't placed an order with us, no worries! We'd still love for you to be a
            part of this community. Simply email us and begin selling or connecting with enthusiasts from
            all facets of the world.
          </p>
        </div>
      </div>
    )
  },
  {
    id: uuid(),
    title: 'What can I expect from your blog?',
    category: 'our_comm',
    description: (
      <div>
        <p>
          You can expect a lot from our blog ( coming soon), including news & updates, tips, events & meetups announcements,
          funny & interactive posts, and more!
        </p>
      </div>
    )
  },

];
