import React from 'react';
import './home_button.scss';
import PropTypes from 'prop-types';

const propTypes = {
  onClick: PropTypes.func
};

const HomeButton = ({ onClick }) => {
  return (
    <button
      type="button"
      className="home_button"
      onClick={onClick}
    >
      Apply Today
    </button>
  );
};

HomeButton.propTypes = propTypes;

export default HomeButton;