import React from 'react';
import { Footer, Header, Accordion } from 'components';
import data from './data';

const Privacy = () => {
  return (
    <div className="common_page">
      <Header subTitle="Privacy Policy" />

      <div className="common_page-wrapper">
        <Accordion data={data} />
      </div>

      <Footer />
    </div>
  );
};

export default Privacy;